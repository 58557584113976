
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.App {
  text-align: center;
  /* border: 1px solid black; */
  height: auto;
  padding-bottom: 20%;
  width: 85%;
  max-width: 1200px;
  margin: auto;
  min-width: 350px;
}
.particlesBackground{
  position: absolute;
  z-index: -1;
}


.mainContentWrapper{
  margin-top: 30px;
  height: 100%;
}

.wrapperOfDiv{

  height: 100%;
  border-radius: 10px 0px 0px 50px;
}
.mainFrame{
  /* border: 1px solid black; */
  background-color: #F5F8F9;
  height: 100%;
  width: 100%;
  border-radius: 50px 0px 50px 50px;
}


.navDiv{
  /* border: 1px solid black; */
  margin-left: 53%;  
}

.leftPanel{
  float: left;
  width: 22%;
  height: 100%;
  background-color: #F5F8F9;
  border-radius: 0px 0px 0px 50px;
}

.leftPanelPhone{
  display: none;
}

@media only screen and (max-width: 1400px) {
  .navDiv {
    margin-left: 45%; 
  }
}
@media only screen and (max-width: 1185px) {
  .navDiv {
    margin-left: 35%; 
  }
}
@media only screen and (max-width: 989px) {
  .navDiv {
    margin-left: 0%; 
    border-bottom: 1px solid #5F6F81;
  }
}
@media only screen and (max-width: 865px) {
  .leftPanel{
    display: none;
  }
  .leftPanelPhone{
    display: block;
    background-color: #F5F8F9;
  }
  .mainFrame{
    /* border: 1px solid black; */
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 50px 50px;
  }

}


