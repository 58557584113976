@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.IconSize{
    height: 15%;
}

.avatar{
    height: 150px;
    padding-Top: 40px;
}


.centerIcons{
    padding-top: 10px;
    margin: auto;
    width: 50%;
}

.outterDiv{
    height: 100%;
    color: #5F6F81;
}

.tileSideBar{
    font-size: 24px;
    font-family: "Inter";
    font-weight: 500;
}
.textAtBtoom{
    font-size: 12px;
}
.POSToutterDiv{
    display: none;
    color: #5F6F81;
}
.PhoneoutterDiv{
    display: none;
    color: #5F6F81;
}


@media only screen and (max-width: 1185px) {
    .avatar{
        height: 110px;
    }
    .tileSideBar{
        font-size: 20px;
        font-family: "Inter";
        font-weight: 500;
    }

    .POSToutterDiv{
        display: block;
        
    }
    .outterDiv{
        display: none;
    }
    .PhoneoutterDiv{
        display: none;
    }
}
@media only screen and (max-width: 865px) {
    .avatar{
        height: 150px;
    }
    .tileSideBar{
        font-size: 30px;
        font-family: "Inter";
        font-weight: 500;
    }
    .outterDiv{
        height: 100%;
        color: #5F6F81;
    }

    .POSToutterDiv{
        display: none;
    }
    .outterDiv{
        display: none;
    }
    .PhoneoutterDiv{
        display: block;
        
    }
    .avatar{
        height: 120px;
        padding-Top: 10px;
    }
}