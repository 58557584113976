@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.wrapper{
    text-align: left;
    padding: 40px;
    font-family: "Inter", sans-serif;
    color: #5F6F81;
}

.title{
    text-align: left;
    font-weight: 600;
    font-size: 24px;
}
.subTitle{
    padding-top: 8px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

.content{
    font-size: 15px;
    text-align: left;
    font-weight: 400;
    line-height: 1.6;
    text-align: justify;
    text-justify: inter-word;

}