.wrapperContactMe{
    height: 100%;
    width: 100%;
    padding: 50px;
}
.Post{
    display: none;
}
@media only screen and (max-width: 989px) {
    .Post{
        display: block;
    }
    .Pre{
        display: none;
    }
  }