@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');


.nav{
    background-color: white;
    padding-top: 16px;
    padding-bottom: 12px;
    // border: 1px solid black;
    border-radius: 10px 50px 0px 0px;  
    display: inherit;
    justify-content: end;
}
