@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);

body {
  margin: 0;
  /* background-color:#6b6e7b; */
  background-image: url(/static/media/shattered_@2X.6e78a9b9.png);
  font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); */

.App {
  text-align: center;
  /* border: 1px solid black; */
  height: auto;
  padding-bottom: 20%;
  width: 85%;
  max-width: 1200px;
  margin: auto;
  min-width: 350px;
}
.particlesBackground{
  position: absolute;
  z-index: -1;
}


.mainContentWrapper{
  margin-top: 30px;
  height: 100%;
}

.wrapperOfDiv{

  height: 100%;
  border-radius: 10px 0px 0px 50px;
}
.mainFrame{
  /* border: 1px solid black; */
  background-color: #F5F8F9;
  height: 100%;
  width: 100%;
  border-radius: 50px 0px 50px 50px;
}


.navDiv{
  /* border: 1px solid black; */
  margin-left: 53%;  
}

.leftPanel{
  float: left;
  width: 22%;
  height: 100%;
  background-color: #F5F8F9;
  border-radius: 0px 0px 0px 50px;
}

.leftPanelPhone{
  display: none;
}

@media only screen and (max-width: 1400px) {
  .navDiv {
    margin-left: 45%; 
  }
}
@media only screen and (max-width: 1185px) {
  .navDiv {
    margin-left: 35%; 
  }
}
@media only screen and (max-width: 989px) {
  .navDiv {
    margin-left: 0%; 
    border-bottom: 1px solid #5F6F81;
  }
}
@media only screen and (max-width: 865px) {
  .leftPanel{
    display: none;
  }
  .leftPanelPhone{
    display: block;
    background-color: #F5F8F9;
  }
  .mainFrame{
    /* border: 1px solid black; */
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 50px 50px;
  }

}



.nav{background-color:white;padding-top:16px;padding-bottom:12px;border-radius:10px 50px 0px 0px;display:inherit;justify-content:end}

.IconSize{
    height: 15%;
}

.avatar{
    height: 150px;
    padding-Top: 40px;
}


.centerIcons{
    padding-top: 10px;
    margin: auto;
    width: 50%;
}

.outterDiv{
    height: 100%;
    color: #5F6F81;
}

.tileSideBar{
    font-size: 24px;
    font-family: "Inter";
    font-weight: 500;
}
.textAtBtoom{
    font-size: 12px;
}
.POSToutterDiv{
    display: none;
    color: #5F6F81;
}
.PhoneoutterDiv{
    display: none;
    color: #5F6F81;
}


@media only screen and (max-width: 1185px) {
    .avatar{
        height: 110px;
    }
    .tileSideBar{
        font-size: 20px;
        font-family: "Inter";
        font-weight: 500;
    }

    .POSToutterDiv{
        display: block;
        
    }
    .outterDiv{
        display: none;
    }
    .PhoneoutterDiv{
        display: none;
    }
}
@media only screen and (max-width: 865px) {
    .avatar{
        height: 150px;
    }
    .tileSideBar{
        font-size: 30px;
        font-family: "Inter";
        font-weight: 500;
    }
    .outterDiv{
        height: 100%;
        color: #5F6F81;
    }

    .POSToutterDiv{
        display: none;
    }
    .outterDiv{
        display: none;
    }
    .PhoneoutterDiv{
        display: block;
        
    }
    .avatar{
        height: 120px;
        padding-Top: 10px;
    }
}
.wrapper{
    text-align: left;
    padding: 40px;
    font-family: "Inter", sans-serif;
    color: #5F6F81;
}

.title{
    text-align: left;
    font-weight: 600;
    font-size: 24px;
}
.subTitle{
    padding-top: 8px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

.content{
    font-size: 15px;
    text-align: left;
    font-weight: 400;
    line-height: 1.6;
    text-align: justify;
    text-justify: inter-word;

}
.wrapperContactMe{
    height: 100%;
    width: 100%;
    padding: 50px;
}
.Post{
    display: none;
}
@media only screen and (max-width: 989px) {
    .Post{
        display: block;
    }
    .Pre{
        display: none;
    }
  }

@media only screen and (max-width: 989px) {
    .TabletAnDPhone {
      display: none;
    }
  }
